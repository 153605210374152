









.copy-cell-renderer {
  display: inline-block;
}
